// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

.pad-buttons {
  background: var(--ion-color-medium-tint);
  padding: 15px 10px;

  .button-noBox,
  .button-number {
    margin: 0;
    padding: 2px;
    font-size: 20px;
    width: 100%;
    color: var(--ion-text-color);
    --background: var(--ion-background-color);
    --background-activated: var(--ion-background-color);
    --background-hover: var(--ion-background-color);
  }

  .button-noBox {
    --background: transparent;
    --background-hover: transparent;
  }

  .button-wrap {
    margin: 0;
    padding: 0;
  }
}
