// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

.pin-modal {
  .pin-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-height: 600px) {
      padding-bottom: 25px;
    }
    .title {
      color: var(--ion-text-color);
      font-weight: 500;
      font-size: 25px;
      text-align: center;
      line-height: 34px;
      margin-top: 20%;
      width: 300px;

      @media (max-width: 350px) {
        font-size: 17px;
        line-height: 20px;
      }
    }
  }

  .wrap-button {
    padding: 0;
  }

  .attempts-remaing {
    margin-top: 20px;
    font-size: 15px;
    color: var(--ion-color-primary);
  }
}
