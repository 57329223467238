// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

.app-wrapper,
ion-modal {
  max-width: var(--max-width-app);
  margin: auto;
  &:has(.event-overview-page) {
    max-width: 100%;
  }
}

ion-card {
  box-shadow: var(--ion-box-shadow);
}

body {
  overflow-x: hidden;
  font-family: var(--ion-font-family);
  background-image: url("../../public/assets/background/pay2stay-background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (min-width: 450px) {
    background: var(--ion-color-dark);
  }
}

.no-border {
  --border-width: 0 !important;
  border-width: 0 !important;
  --border-color: transparent;
}

ion-toolbar {
  --background: transparent;
}

ion-content {
  background: var(--background-primary);
}

ion-header {
  background: var(--background-primary);
}

ion-footer {
  background: var(--background-primary);
}

.custom-content {
  border-radius: 30px 30px 0 0;
  overflow: hidden;
}

.loader-style {
  display: flex;
  margin: 10px auto;
  width: 30px;
  height: 30px;
  border: 5px solid black;
  border-bottom-color: var(--ion-color-primary);
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1.7s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

ion-modal {
  &#scan-qr-modal::part(content),
  &#guide-modal::part(content) {
    --height: auto;
    width: 90%;
    border-radius: 20px;
  }

  &#install-ios-app-modal {
    --background: transparent;
  }

  &#install-ios-app-modal::part(content),
  &#fullscreen-modal::part(content) {
    --height: 100%;
    width: 100%;
  }
}

ion-datetime {
  color: var(--ion-text-color);
}

.primary-btn {
  min-height: 55px;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  --background: var(--accents-contrast);
  --background-hover: var(--background-gradient-2);
  transition: all 0.3s linear;
  text-transform: initial;

  &::part(native) {
    padding: 0 20px;
    &:hover {
      background: var(--background-gradient-2);
    }
  }
}

.safe-area {
  width: 100%;
  height: var(--ion-safe-area-top);
  background: linear-gradient(
      180deg,
      rgb(133, 130, 130),
      var(--background-primary)
    )
    no-repeat;
}

.ion-page {
  ion-input,
  ion-textarea {
    --highlight-color-focused: none;
  }
}

.empty-data-style {
  border: 1px solid var(--border-secondary);
  border-radius: 20px;
  padding: 10px;
  text-align: center;
  margin: 20px 0;
  line-height: 25px;
  font-size: 16px;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-banner-event {
  background: var(--ion-color-medium);
}
