// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

.header-component {
  background-color: var(--background-primary);

  .header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 30px 15px;
    position: relative;
  }

  .logo-inner {
    display: flex;
    align-items: center;
    height: 1em;

    &:hover {
      cursor: pointer;
    }

    img {
      width: 150px;
      min-height: 70px;

      @media only screen and (min-width: 400px) {
        min-height: 75px;
      }
    }
  }

  ion-back-button {
    --icon-margin-top: -1px;
    --icon-margin-end: 1px;
    position: relative;
    font-size: 16px;
    font-weight: 400;
  }

  .btn-back-custom {
    margin: 0;
    font-weight: 400;
    position: relative;
    font-size: 16px;
    left: -7px;

    ion-icon {
      position: relative;
      margin-top: -1px;
      margin-right: 1px;
      font-size: 25px;
    }
  }
}
