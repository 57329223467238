/* Copyright 2024 by P2S Software LLC.
 * This file contains proprietary and confidential information.
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #ff0063;
  --ion-color-primary-rgb: 255, 0, 99;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #e00057;
  --ion-color-primary-tint: #ff1a73;
  --ion-color-primary-disabled: #ffbdd5;
  --ion-color-primary-background: #f4ebf2;

  /** secondary **/
  --ion-color-secondary: #913aff;
  --ion-color-secondary-rgb: 145, 58, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #8033e0;
  --ion-color-secondary-tint: #9c4eff;

  /** tertiary **/
  --ion-color-tertiary: #20a4f3;
  --ion-color-tertiary-rgb: 32, 164, 243;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #1c90d6;
  --ion-color-tertiary-tint: #36adf4;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #011627;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #044c8b;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #bbbdc1;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e8e8eb;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-light-tint-rgb: 245, 246, 249;

  /** accents color **/
  --accents-primary: #ff0063;
  --accents-secondary: #913aff;
  --accents-tertiary: #20a4f3;
  --accents-contrast: #000000;

  /** background **/
  --background-primary: #fbfbfb;
  --background-subtle: #ecf1f4;
  --background-gradient: linear-gradient(
    12.86deg,
    #250058 -4.26%,
    #a360dc 58.67%,
    #ff0063 100.63%
  );
  --background-gradient-2: linear-gradient(
    12.86deg,
    #a360dc -4.26%,
    #ff0063 107.62%
  );
  --background-disabled: #cccccc;
  --ion-background-color: var(--background-primary);

  /** text **/
  --text-color-primary: #4a4a68;
  --text-color-subtle: #0e0e2c;
  --text-color-contrast: #ffffff;
  --text-color-disabled: #cccccc;
  --ion-text-color: var(--text-color-primary);

  /** border **/
  --border-primary: #000000;
  --border-secondary: #c2c4c792;
  --border-tertiary: #e8e8eb;
  --border-divider: #913aff4d;
  --ion-border-color: #e8e8eb;

  /** box-shadow **/
  --ion-box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;

  /**font **/
  --ion-font-family: "Poppins";

  /* App max width */
  --max-width-app: 450px;
}
