// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

ion-modal#confirm-modal::part(content) {
  --height: auto;
  width: 90%;
  border-radius: 20px;
}

#confirm-modal {
  align-items: center;
  background: rgb(0 6 15 / 59%);
  max-width: var(--max-width-app);
  margin: auto;
  color: var(--ion-text-color);
  font-size: 18px;

  &.full-with {
    max-width: 100%;
  }

  .buttons-confirm {
    padding: 10px;
    display: flex;

    ion-button {
      flex: 1;
    }
  }

  .title {
    text-align: center;
    font-weight: 600;

    ion-icon {
      font-size: 30px;
      margin: 0 10px;
      position: relative;
      top: 5px;
      color: var(--ion-color-primary);
    }
  }

  .confirm-wrap {
    padding: 10px 16px;
  }

  .wrap-close-btn {
    min-height: 25px;
  }

  .close-button {
    font-size: 25px;
    position: absolute;
    right: -25px;
    top: -5px;
  }
}
