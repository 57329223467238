// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

.pin-dots {
  display: flex;
  justify-content: space-between;
  margin: 0 auto auto auto;
  padding-top: 33px;

  .circle {
    --circle-diameter: 18px;
    --transition-duration: 200ms;
    border-radius: 50%;
    transition: background-color var(--transition-duration) ease,
      border var(--transition-duration) ease;
    background-color: transparent;
    border: 1px solid var(--ion-color-primary);
    height: var(--circle-diameter);
    width: var(--circle-diameter);
  }

  .filled {
    background-color: var(--ion-color-primary);
  }
}
