// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

ion-tabs {
  --background: var(--background-primary);
  background: var(--background-primary);

  .custom-tab {
    margin-top: -5px;
    box-shadow: var(--box-shadow);
    --background: var(--background-primary);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 8px;
    border-radius: 8px 8px 0px 0px;
    height: 60px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    img {
      width: 25px;
      height: 25px;
      max-width: none;
    }
  }

  ion-tab-button {
    display: flex;
    align-items: center;
    justify-content: center;

    &::part(native) {
      width: 80px;
      height: 80px;
      margin: auto;
      border-radius: 4px;

      &:hover {
        color: var(--ion-text-color) !important;
      }
    }

    ion-label {
      margin-top: 5px;
    }
  }

  .tab-selected {
    &::part(native) {
      background: var(--accents-secondary);
    }

    ion-label {
      color: var(--text-color-contrast);
    }

    img {
      filter: brightness(930%);
    }
  }
}

ion-router-outlet {
  margin-bottom: 0 !important;
}
