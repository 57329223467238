// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

ion-modal.install-app-modal {
  backdrop-filter: brightness(0.3);
  --height: auto;
  align-items: flex-end;

  &::part(content) {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
}

.install-popup-content {
  width: 100%;
  padding: 15px;
  margin: auto;

  .title-modal {
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 25px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 2rem;
    color: #000;
  }

  ion-item {
    --background: #f9f9fc;
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    --padding-start: 1rem;
    border-radius: 1rem;

    img {
      width: 35px;
      height: 35px;
      margin-right: 21px;
    }

    ion-icon {
      font-size: 35px;
      margin-right: 21px;
    }

    .btn {
      width: 100px;
      text-align: center;
      display: inline-block;
      padding: 9px;
      border-radius: 10px;

      &.solid {
        background: var(--ion-color-primary);
        color: white;
      }

      &.outline {
        border: 1px solid var(--ion-color-primary);
        color: var(--ion-color-primary);
      }
    }

    ion-label {
      margin: 0;

      .title {
        font-size: 12px;
        &.color-danger {
          color: var(--ion-color-danger);
        }
      }
    }
  }
}
