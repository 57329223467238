// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

.install-android-app-btns {
  .wait-install {
    display: flex;
    flex-direction: row;
  }

  .button-event-detail {
    margin: 5px 10px;
  }
}

.progress-bar {
  position: relative;
  overflow: hidden;
  height: 45px;
  padding: 0 !important;

  ion-progress-bar {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    z-index: 10;
  }

  .text {
    color: white;
    z-index: 11;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
