// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

.enter-event-code {
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 25px;
  font-size: 15px;

  .wrap-paste-clipboard .wrap-input {
    border: 2px solid black;

    ion-input {
      font-size: 20px;
    }

    .label-input {
      top: -20px;
    }
  }

  .join-event-btn {
    height: 60px;
  }

  .invite-input {
    color: var(--text-color-primary);
    min-height: 55px;
    font-size: 20px;
    border: none;
    outline: none;
    width: 100%;
    padding-left: 10px;
    background: transparent;

    &::placeholder {
      color: var(--background-disabled);
    }
  }
}
