// Copyright 2024 by P2S Software LLC.
// This file contains proprietary and confidential information.
// Unauthorized copying of this file, via any medium, is strictly prohibited.

.wrap-paste-clipboard {
  ion-row {
    align-items: center;
  }

  .paste-btn {
    &:hover {
      opacity: 0.7;
    }

    button {
      background-color: transparent;
      color: #000;
      display: flex;
      align-items: baseline;
      justify-content: center;
      padding: 0 20px;

      img {
        margin-right: 10px;
        width: 20px;
      }

      span {
        color: var(--accents-primary);
        font-size: 14px;
        position: relative;
        top: -5px;
      }
    }
  }

  .wrap-input {
    font-size: 14px;
    border: 1px solid var(--border-secondary);
    border-radius: 5px;
    position: relative;
    margin-right: 10px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    ion-input {
      min-height: 55px;
      --padding-start: 15px;
      --placeholder-color: var(--background-disabled);
      font-size: 14px;
      color: var(--text-color-primary);
    }

    .label-input {
      position: absolute;
      background: var(--background-primary);
      padding: 5px;
      z-index: 99;
      top: -15px;
      left: 10px;
      max-height: 25px;
      overflow: hidden;
      color: var(--text-color-primary);

      @media only screen and (min-width: 400px) {
        max-height: 30px;
      }
    }
  }
}
